<template>
  <article class="drawed-border relative text-grey-light">
    <div
      v-if="validityFrom"
      class="bg-secondary-dark flex justify-center items-center absolute -top-[3.25rem] left-1/2 w-24 h-24 rounded-full transform -translate-x-1/2 z-10"
    >
      <span class="text-primary text-sm"
        >od <time :datetime="validityFrom" class="font-extrabold text-lg">{{ validityFromFormatted }}</time></span
      >
    </div>
    <div class="bg-secondary relative w-full h-full px-5 lg:px-44 py-16 lg:py-32">
      <Heading v-if="heading" :text="heading" class="text-center mb-10" />
      <div v-html="text" class="text-sm lg:text-base notice__text leading-relaxed"></div>
    </div>
  </article>
</template>

<script setup>
const props = defineProps({
  heading: String,
  text: String,
  validityFrom: String,
})

const validityFromFormatted = computed(() => {
  if (!props.validityFrom) return null
  const _validityFromArr = props.validityFrom?.split('-')?.reverse() ?? []
  if (_validityFromArr.length) _validityFromArr.pop()
  return _validityFromArr.length ? _validityFromArr.join('. ') + '.' : null
})
</script>

<style>
.notice__text p {
  @apply mb-5 last:mb-0;
}
</style>
